// Import mixins
@import 'components/mixins.scss';

////////////////////////////////////////////////////////////////////////////////////////////
// DARK THEME STYLES
th.ant-table-cell {
  font-style: normal;
  font-weight: bold !important;
  font-size: 16 !important;
  color: #1a233a !important;
}
[data-kit-theme='dark'] {
  .ant-table-column-has-sorters {
    &:hover {
      background: $dark-gray-5 !important;
    }
  }
  .ant-table-column-sort {
    background: $dark-gray-5 !important;
  }
}
